import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import {LoginComponent} from './login/login.component'
import {AuthGuard} from './auth/auth.guard'

import { NgModule } from '@angular/core';

const routes: Routes = [
  { path: 'pages',canActivate: [AuthGuard], loadChildren:()=>import('./pages/pages.module').then(m=>m.PagesModule)},
  { path: 'login', component: LoginComponent,children: [], },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages', },
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
  
})
export class AppRoutingModule {
}
