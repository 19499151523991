import { Component, Input, OnInit } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { UsersService } from '../../../@core/data/users.service';
import { LayoutService } from '../../../@core/data/layout.service';
import { FolloupDialogService } from '../../../pages/common/followup-dialog/folloup-dialog.service';
import {QuickPaymentDialogService} from '../../../pages/common/quick-payment-dialog/quick-payment-dialog.service'
import { environment } from '../../../../environments/environment';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { ApiService } from '../../../@core/data/api.service';
import { Router } from '@angular/router'
import { ProgressbarService } from '../../../@core/utils/progressbar.service';
import { MessageService } from '../../../@core/utils/message.service';

import {
  NbSearchService,
} from '@nebular/theme';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  @Input() position = 'normal';
  user: any;
  version: string = environment.version;

  userMenu = [
    // { title: 'Profile' },
    { title: 'Change Password', link: '/pages/change-password' },
    { title: 'Log Out', link: '/login' },
    { title: '------------------------------' },
    { title: 'Version: ' + this.version, link: '/pages/release-notes' },
  ];
  userName: string;

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private userService: UsersService,
    private layoutService: LayoutService,
    private analyticsService: AnalyticsService,
    private searchService: NbSearchService,
    private folloupDialog: FolloupDialogService,
    private quickPaymentDialogService:QuickPaymentDialogService,
    private apiService: ApiService,
    private router: Router,
    private progressbar: ProgressbarService,
    private messageService: MessageService
  ) {
    this.searchService.onSearchSubmit()
      .subscribe((data: any) => {
        if (data != undefined && data.term != "" && data.term.trim() != "") {
          debugger;
          var params = { SearchValue: data.term.trim() };
          this.progressbar.start()
          this.apiService.getGlobalSearchResult(params).then(response => {
            this.progressbar.stop()
            let data = response;
            if (data != undefined && data != null) {
              if (data.errorId == 0) {
                this.router.navigate(['/pages/enquiry-entry'], { queryParams: { enquiryId: data.result } })
              }
              else {
                this.messageService.ShowError(data.message);
              }
            }
            else {
              //this.message = "Invalid Request";
            }
          });
        }
      })
  }
  ngOnInit() {
    this.userService.getUser().subscribe((users: any) => this.user = users);
    this.userName = localStorage.getItem("userName");
  }
  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }

  openFollowupDialog() {
    this.folloupDialog.openDialog();
  }

  quickPayment(){
    this.quickPaymentDialogService.openDialog(null,'485px','650px');
  }

}
