import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
// import { Http, Headers } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router} from '@angular/router';


const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})

export class HttpService {

  constructor(
    private http: HttpClient,
    private router: Router
    ) { }


  createAuthorizationHeader(headers: HttpHeaders) {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("x-token");

    // headers.append('userId', userId);
    // headers.append('token', token);

      headers
    .set('userId', userId)
    .set('token', token);
  }

  post(apiName, data): any {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("x-token");
    let headers = new HttpHeaders()
    if(userId != null){
      headers=headers
      .set('userId', userId)
      .set('token', token);
    }

    if(environment.isWindowsApp){
      const localIpAddress = localStorage.getItem("localIpAddress");
      const macAddress = localStorage.getItem("macAddress");
      headers=headers
      .set('LocalIpAddress', localIpAddress)
      .set('MacAddress', macAddress);
    }

    return this.http
      .post(API_URL + apiName, data, { 'headers' : headers , responseType:'json' })
      .toPromise()
      .then(res => {
        return res;
      })
      .catch(error => {
        if (error != undefined && error != null) {
          //Unauthorized user logout
          if (error.status === 401 && error.statusText === 'Unauthorized') {
            this.router.navigate(['/login']);
          }
        }
      });
  }

  get(url): any {
    
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("x-token");

    let headers = new HttpHeaders()
    .set('userId', userId)
    .set('token', token);;

    
    //this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + url, { headers: headers, responseType:'json' })
      .toPromise()
      .then(res => {
        // if(res.json().errorId ===0){
        //   alert("suceess");
        //  } 
        return res;
      })
      .catch(error => {
       
        if (error != undefined && error != null) {
          //Unauthorized user logout
          if (error.status === 401 && error.statusText === 'Unauthorized') {
            this.router.navigate(['/login']);
          }
        }
      });
  }

  getImage(url): any {
    
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("x-token");

    let headers = new HttpHeaders()
    .set('userId', userId)
    .set('token', token);;


    return this.http
      .get(API_URL + url, { headers: headers })
      .toPromise()
      .then(res => {
        // if(res.json().errorId ===0){
        //   alert("suceess");
        //  } 
        return res;
      })
      .catch(error => {
        if (error != undefined && error != null) {
          //Unauthorized user logout
          if (error.status === 401 && error.statusText === 'Unauthorized') {
            this.router.navigate(['/login']);
          }
        }
      });
  }

  getFile(url): any {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("x-token");

    let headers = new HttpHeaders()
    .set('userId', userId)
    .set('token', token);;

    return this.http
      .get(API_URL + url, { headers: headers,responseType:'blob'})
      .toPromise()
      .then(res => {
        return res;
      })
      .catch(error => {
        if (error != undefined && error != null) {
          //Unauthorized user logout
          if (error.status === 401 && error.statusText === 'Unauthorized') {
            this.router.navigate(['/login']);
          }
        }
      });
  }

  
}
