import { Injectable } from '@angular/core';
import { HttpService } from '../http.service'

@Injectable({
  providedIn: 'root'
})
export class UserRightService {

  constructor(private httpService: HttpService) { }

  getUserRights() : any {
    return this.httpService.get('get-userrights');
  }

  getUserRight(link:string) : any {
    return this.httpService.get('get-userright?link='+link);
  }

  
}
