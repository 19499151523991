import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, from } from 'rxjs';
import { EnquiryService } from '../../../@core/data/api/enquiry.service';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from '../../../@core/data/api.service';
import { FolloUpService } from '../../../@core/data/api/follo-up.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MessageService } from '../../../@core/utils/message.service';
import { DateTimeAdapter } from 'ng-pick-datetime';

@Component({
  selector: 'app-followup-dialog',
  templateUrl: './followup-dialog.component.html',
  styleUrls: ['./followup-dialog.component.css']
})
export class FollowupDialogComponent implements OnInit {
  enquiryCtrl = new FormControl();
  enquiryCtrl2 = new FormControl();
  filteredEnquiries: Observable<any>;
  users: any;
  form: FormGroup;
  enquiryId: number;
  searchEnquiryVisible:boolean = true;

  constructor(
    private message: MessageService,
    private folloUpService: FolloUpService,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private ernquiryService: EnquiryService,
    public dialogRef: MatDialogRef<FollowupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    dateTimeAdapter: DateTimeAdapter<any> 
  ) {
    dateTimeAdapter.setLocale('en-in'); 
   }

  ngOnInit() {
    this.createForm();
    this.fillDropDown();
    this.setInitialControl();
    
    if(this.data != undefined && this.data != null){
      this.enquiryCtrl2.disable({onlySelf:true});
      this.enquiryCtrl2.setValue(this.data.name);
      this.enquiryId = this.data.enquiryId;
      this.searchEnquiryVisible = false;
    }
    
  }

  createForm() {
    this.form = this.formBuilder.group({
      isFollowUp: [true],
      followUpDate: ['', Validators.required],
      note: ['', Validators.required],
      assignmentId: [''],
    });
  }

  setInitialControl() {
    this.enquiryCtrl.valueChanges
      .pipe(debounceTime(700)).subscribe(val => {
        this.ernquiryService.filterEnquiryRecords(val)
          .then(res => {
            if (res != undefined && res.result != undefined)
              this.filteredEnquiries = res.result;
          });
      });
  }

  submit(): void {
    if (this.form.invalid) return;
    let params = this.form.value;

    let data = this.enquiryCtrl.value;
    if (data != undefined || data != null) {
      if (data.id != undefined) {
        this.enquiryId = data.id;
      }
    }

    if (this.enquiryId > 0) {
      params['enquiryId'] = this.enquiryId
    }

    this.folloUpService.saveFollowUp(params).then(res => {
      if (res != undefined && res.errorId === 0) {
        this.message.ShowSuccess(res.message);
        this.dialogRef.close();
      }
      else {
        this.message.ShowError(res.message);
      }
    })

  }

  cancel(){
    this.dialogRef.close();
  }
  fillDropDown() {
    const params = { IsUsersRequiredByType: true, UserTypes: ['Candidate'] };
    this.apiService.getFilter(params).then(res => {
      if (res != undefined && res.errorId === 0) {
        this.users = res.result.usersByType;
      }
    });
  }

  changeEnquiry(data: any) {
    if (data == undefined) {
      this.enquiryId == 0;
    }
    else {
      return data.name;
    }
  }



}
