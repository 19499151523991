import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  setUserDetails(userId: number, token: string,userName: string) {
    localStorage.setItem('userId', userId.toString());
    localStorage.setItem('x-token', token);
    localStorage.setItem('userName', userName);
  }

  logout() {
    localStorage.removeItem('userId');
    localStorage.removeItem('x-token');
    localStorage.removeItem('userName');
    localStorage.removeItem('menuAccess');
  }

  isLogin(): boolean {
    let result = localStorage.getItem("x-token");
    if (result != undefined && result != null)
      return true;
    else
      return false
  }

  

}
