import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { DataModule } from './data/data.module';
import { AnalyticsService } from './utils/analytics.service';
import { MessageService } from './utils/message.service';
import { ProgressbarService} from './utils/progressbar.service'
import { DateService} from './utils/date.service'
import { AuthService} from './utils/auth.service'
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { MyHttpLogInterceptorService} from '../auth/my-http-log-interceptor.service'
import {GeneralService } from './utils/general.service'


export const CORE_PROVIDERS = [
  ...DataModule.forRoot().providers,
]

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: []
})
export class CoreModule { 
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule>{
    return{
      ngModule : CoreModule,
      providers:[
        ...CORE_PROVIDERS,
        AnalyticsService,
        MessageService,
        ProgressbarService,
        DateService,
        AuthService,
        GeneralService,
        { provide: HTTP_INTERCEPTORS, useClass: MyHttpLogInterceptorService, multi: true }  
      ]
    }
  }

}
