import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()

export class MessageService {

  constructor(private toastrService: ToastrService) { 
    
  }

  ShowSuccess(message: string){
    this.toastrService.success(message,"Success",{timeOut: 3000});
  }

  ShowError(message: string){
    this.toastrService.error(message,"Error",{timeOut: 5000});
  }

}
