import { Injectable } from '@angular/core';

@Injectable()
export class DateService {

  constructor() { }

  convertToDate(value: any): any {
    if (value != undefined && value != null && value instanceof Date) {
      let result = new Date(value);
      return result.toDateString();
    }
    else {
      if (value != undefined && value != null) {
        let result = new Date(value);
        return result.toDateString();
      }else{
        return null;
      }
    }
  }

  convertToDateTime(value: any): any {
    if (value != undefined && value != null && value instanceof Date) {
      let result = new Date(value);

      // var resultString = result.toDateString()+' '+result.toTimeString();


      return result.toString();
    }
    else {
      return null;
    }
  }


}
