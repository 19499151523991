import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Data } from '@angular/router';
import { AuthService } from '../@core/utils/auth.service'
import { GlobalService } from '../@core/data/global.service'

import { of, Observable, ReplaySubject, BehaviorSubject } from "rxjs"



@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
  //   return true;
  // }
  constructor(private router: Router,
    private authService: AuthService,
    private globalService: GlobalService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const IsLogin = this.authService.isLogin();
    if (IsLogin === true) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }


  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const IsLogin = this.authService.isLogin();
    if (IsLogin) {
      let componentName: any = route.component;
      if (componentName === undefined) return true;
      else {
        componentName = componentName.name;
      }
     
      let IsView = this.globalService.IsValidComponent(state.url, componentName);
      if (IsView)
        return true;
      else
        return false
    }
    else {
      this.router.navigate(['/login']);
      return false;
    }
  }

  auth(fallback): Observable<boolean> {

    return this.globalService.getRights(fallback).subscribe(res => {
      if (res != undefined) {
        let data = res;
        let view = data.view;
        if (view) {
          return true;
        }
        else {
          return false;
        }
      }
    });
  }




}
