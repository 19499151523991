import { Injectable } from '@angular/core';
import { FollowupDialogComponent } from './followup-dialog.component'
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Injectable({
  providedIn: 'root'
})
export class FolloupDialogService {

  constructor(public dialog: MatDialog) { }

  openDialog(foramData:any = null):void{
    const dialogRef = this.dialog.open(FollowupDialogComponent,{
      data: foramData,
      height: '410px',
      width: '500px',
    });
  }
}
