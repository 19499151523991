import { Component, OnInit } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms'
import { ApiService } from '../@core/data/api.service';
import { AuthService } from '../@core/utils/auth.service';

import { environment } from '../../environments/environment';
import { Http } from '@angular/http';
import { ProgressbarService } from '../@core/utils/progressbar.service';
import { removeSpaces } from '../@core/utils/general.service'
//var address = require("../../../src/address");


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  requestForm: FormGroup;
  isProgress: boolean = false;
  message: string = " ";
  approvalMessgae: string = " ";
  hide = true;
  isWindowsApp = environment.isWindowsApp;
  version: string = environment.version;
  isLoginVisible = false;
  isRequestVisible = false;
  isErrorVisble = false;
  private systemDetails: any;


  constructor(
    private http: Http,
    private router: Router,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private authService: AuthService,
    private progressBar: ProgressbarService,

  ) { }

  ngOnInit() {
    this.isLoginVisible = false;
    this.isRequestVisible = false;
    this.isErrorVisble = false;

    this.authService.logout();
    this.createLoginForm();
    this.createRequestForm();
    if (!this.isWindowsApp) {
      this.isLoginVisible = true;
    }
    else {
      this.VerifyMacAddress();
    }
    // address.mac(function (err, addr) {
    //   if (err) throw err
    //   alert(addr);
    // });
  }

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, removeSpaces]],
      password: ['', [Validators.required, removeSpaces]]
    });
  }

  createRequestForm() {
    this.requestForm = this.formBuilder.group({
      requesterName: ['', Validators.required],
      requesterEmail: [''],
      macAddress: ['', Validators.required]
    });
    this.requestForm.get("macAddress").disable();
  }


  tryLogin() {
    if (this.loginForm.invalid) return;

    this.message = " ";
    this.isProgress = true;
    var params = this.loginForm.value;
    if (this.isWindowsApp) {
      params['IsDeskopAllow'] = true;
    }
    else {
      params['IsWebAllow'] = true;
    }
    this.apiService.validateUser(params).then(
      response => {
        let data = response;
        if (data != undefined && data != null) {
          if (data.errorId == 0) {
            let userId = data.result.userId;
            let token = data.result.token;
            let isAdmin = data.result.isAdmin
            var menus = data.result.userMenus;
            var dashboard = menus.filter(menu => menu.title === 'Dashboard');
            //this.globalService.setMenus(data.result.userMenus);
            sessionStorage.setItem("userMenu", menus);
            localStorage.setItem("isAdmin",isAdmin);
            this.authService.setUserDetails(userId, token, data.result.name);
            if (dashboard != undefined && dashboard != null && dashboard.length > 0) {
              this.router.navigateByUrl('/pages/dashboard');
            }
            else {
              this.router.navigate(['./pages/pages.module#PagesModule']);
            }
            this.isProgress = false;
          }
          else {
            this.isProgress = false;
            this.message = data.message;
          }
        }
        else {
          this.isProgress = false;
          this.message = "Invalid Request";
        }

      });


  }

  clearMessage() {
    this.message = "";
  }

  VerifyMacAddress() {

    this.http.get('assets/data.json').subscribe(res => {
      if (res != undefined) {
        var data = res.json()
        if (data != undefined) {
          //Set macddress and localIpAddress
          localStorage.setItem("localIpAddress",data['systemdetails'].ipAddress);
          localStorage.setItem("macAddress",data['systemdetails'].mac.toUpperCase());


          this.requestForm.get('macAddress').setValue(data['systemdetails'].mac.toUpperCase());
          this.systemDetails = data['systemdetails'];
          this.verifyAppAccess(data['systemdetails'].mac.toUpperCase());
        }
        else {
          this.isErrorVisble = true;
          this.approvalMessgae = "Machine details not found";
        }
      }
      else {
        this.isErrorVisble = true;
        this.approvalMessgae = "Getting Error from Server.";
      }

    });
  }

  accessRequest() {
    if (this.requestForm.invalid) return;
    let params = this.requestForm.value;
    params['osplatform'] = this.systemDetails.osplatform;
    params['macAddress'] = this.systemDetails.mac.toUpperCase();
    params['loggedInUserName'] = this.systemDetails.userName;
    params['osVersion'] = this.systemDetails.versionString;
    params['ipAddress'] = this.systemDetails.ipAddress;
    this.isProgress = true;
    this.apiService.accessRequest(params).then(res => {
      if (res != undefined) {
        if (res.errorId === 0) {
          this.approvalMessgae = res.result.message;
          this.isRequestVisible = false;
          this.isErrorVisble = true;
        }
      }
      this.isProgress = false;
    });

  }

  verifyAppAccess(mac: string) {

    var params = { "MacAddress": mac };
    this.progressBar.start();
    this.apiService.verifyAppAccess(params).then(res => {
      if (res != undefined && res != null) {
        var data = res;
        if (data.errorId === 0) {
          if (data.result.isApproved === true) {
            this.isRequestVisible = false;
            this.isErrorVisble = false;
            this.isLoginVisible = true;
          }
          else {
            if (data.result.isExists === true) {
              this.approvalMessgae = data.result.message;
              this.isLoginVisible = false;
              this.isRequestVisible = false;
              this.isErrorVisble = true;
            }
            else {
              this.isErrorVisble = false;
              this.isLoginVisible = false;
              this.isRequestVisible = true;
            }
          }
        } else {
          this.isErrorVisble = true;
          this.approvalMessgae = data.message;
        }
      }
      else {
        this.isErrorVisble = true;
        this.approvalMessgae = "Server Error.";
      }
      this.progressBar.stop();
    })

  }





}
