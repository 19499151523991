import { Injectable } from '@angular/core';
import { AbstractControl  } from '@angular/forms'

@Injectable({
  providedIn: 'root'
})

export class GeneralService {
  constructor() { }
}

export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.patchValue(null);
  }
  return null;
}

