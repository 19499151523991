import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsersService } from './users.service';
import { StateService } from './state.service';
import { LayoutService } from './layout.service';
import { ApiService } from './api.service';

const SERVICES = [
  UsersService,
  StateService,
  LayoutService,
  ApiService
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers:[
    ...SERVICES,
  ],
})
export class DataModule { 
  // static forRoot() : ModuleWithProviders{
  //   return <ModuleWithProviders>{
  //     ngModule : DataModule,
  //     providers:[
  //        ...SERVICES,
  //     ]
  //   }
  // }

  static forRoot(): ModuleWithProviders<DataModule> {
    return {
      ngModule: DataModule,
      providers:[
        ...SERVICES,
     ]
    };
  }

}
