import { Component, OnInit,Inject } from '@angular/core';
import { ProgressbarService } from '../../../@core/utils/progressbar.service';
import { ApiService } from '../../../@core/data/api.service';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms'
import { MessageService } from '../../../@core/utils/message.service';
import {  DateService } from "../../../@core/utils/date.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router'

@Component({
  selector: 'app-quick-payment-dialog',
  templateUrl: './quick-payment-dialog.component.html',
  styleUrls: ['./quick-payment-dialog.component.css']
})
export class QuickPaymentDialogComponent implements OnInit {
  cardHeight: number;
  searchResult:any=null;
  paymentModes: any;
  quickSearchForm: FormGroup;
  quickPaymentEntryForm:FormGroup;
  staticFormControl:FormGroup;
  date = new Date();
  isDisabled:boolean=true;
  constructor(
    private router: Router,
    private progressbar: ProgressbarService,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private message: MessageService, 
    private dateService:DateService,
    public dialogRef: MatDialogRef<QuickPaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
   this.fillDropDown();
   this.createQuickSearchPaymentForm();
   this.createStaticFormControl();
   this.createQuickPaymentEntryForm();
   this.enableDisableButton(true);
  }


  createQuickSearchPaymentForm() {
    this.quickSearchForm = this.formBuilder.group({
      searchValue: ['', [Validators.required]]
    });
  
  }

  createStaticFormControl(){
    this.staticFormControl=this.formBuilder.group({
      userKey: [''],
      mobileNo: [''],
      name: [''],
      enquiryId: [''],
    });
  }

  createQuickPaymentEntryForm(){
    this.quickPaymentEntryForm = this.formBuilder.group({
      date: ['',[Validators.required]],
      enquiryId: [''],
      mobileNo: [''],
      name: [''],
      paidAmount: ['',[Validators.required,Validators.min(1)]],
      paymentMode: ['',[Validators.required]],
      remarks: [''],
      userKey: [''],
      bankName:[''],
      chequeNo:['']
    });
    this.quickPaymentEntryForm.patchValue({date:this.date});
  }

  fillDropDown() {
    var params = { IsPaymentModesRequired: true };
    this.progressbar.startBackground();
    this.apiService.getFilter(params).then(
      responce => {
        this.paymentModes = responce.result.paymentModes;
        this.progressbar.stopBackground();
      });
  }

  changePaymentMode(paymentModeId){
    if(paymentModeId==29){
      this.cardHeight=600;
    }else{
      this.cardHeight=515;
    }
  }

  printPaymentReceipt(paymentId: number) {
    this.router.navigate(['/pages/report/invoice/'], { queryParams: { paymentId: paymentId } });
  }
  saveQuickPayment(isPrint){
    if (!this.quickPaymentEntryForm.valid) return;
    var params = this.quickPaymentEntryForm.value;

    if(this.searchResult==null && this.searchResult.enquiryId<0){
      this.message.ShowError('Please search enquiry');
      return false;
    }
    params.enquiryId=this.searchResult.enquiryId;
    params['date'] = this.dateService.convertToDate(params.date);

    this.apiService.saveQuickPayment(params).then(res=>{

      if (res != undefined) {
        if (res.errorId === 0) {
          this.message.ShowSuccess(res.message);
          this.dialogRef.close();
          if(isPrint){
            this.printPaymentReceipt(res.result);
          }
        }
        else{
          this.message.ShowError(res.message);
        }
          
      }
    });
    
  }

  enableDisableButton(isDisabled){
    this.isDisabled=isDisabled;
  }
 
  cancel() {
    this.dialogRef.close();
  }

  quickPaymentSearch(){
    if (!this.quickSearchForm.valid) return;
    var params = this.quickSearchForm.value;

    this.apiService.getQuickPaymentSearch(params).then(res=>{

      if (res != undefined) {
        if (res.errorId === 0) {
          this.staticFormControl.patchValue(res.result);
          this.searchResult=res.result;
          this.enableDisableButton(false);
        }
        else{
          this.staticFormControl.patchValue({userKey:'',mobileNo:'',name:''});
          this.searchResult=null;
          this.enableDisableButton(true);
          this.message.ShowError(res.message);
        }
          
      }
    });
  }
}
