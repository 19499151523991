import { APP_BASE_HREF } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { HttpModule } from '@angular/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ThemeModule } from './@theme/theme.module';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { FlipModule } from 'ngx-Flip';
import { AuthGuard } from './auth/auth.guard'
import { LoginComponent } from './login/login.component';



const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsType: SPINNER.cubeGrid,
  overlayColor: "rgba(253,254,255,0.8)",
  bgsPosition: POSITION.centerCenter,
  bgsSize: 60,
  bgsType: SPINNER.cubeGrid,
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5, // progress bar thickness
};


@NgModule({
  declarations: [AppComponent, LoginComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpModule,
    AppRoutingModule,
    // NgbModule.forRoot(),
    NgbModule,
    ThemeModule.forRoot(),
    CoreModule.forRoot(),
    ToastrModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    FlipModule,
  ],

  bootstrap: [AppComponent],
  providers: [AuthGuard,
    { provide: APP_BASE_HREF, useValue: '/', },
  ],
})
export class AppModule {
}
