import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpService } from './http.service'

const API_URL = environment.apiUrl;

@Injectable()
export class ApiService {


  constructor(private httpService: HttpService) { }

  getUserProfile(userId: number): any {
    return this.httpService.get('get-userprofile?userId=' + userId);
  }

  uploadUserProfile(data: any, userId: number): any {
    return this.httpService.post('upload-userprofile?userId=' + userId, data);
  }

  deleteUserProfile(userId: number): any {
    return this.httpService.get('delete-userprofile?userId=' + userId);
  }

  uploadUserDocument(data: any): any {
    return this.httpService.post('upload-userdocument', data);
  }

  //#region general 
  getFilter(data: any): any {
    return this.httpService.post('get-filter', data);
  }

  downloadMasterTemplates(name: string) {
    return this.httpService.getFile("download-mastertemplates?name=" + name);
  }

  getGlobalSearchResult(data: any): any {
    return this.httpService.post('get-global-serach-result', data);
  }
  //#endregion

  //#region University
  getUniversities(isActive: boolean): any {
    return this.httpService.get('get-universities?IsActive=' + isActive);
  }
  getUniversity(universityId: number): any {
    return this.httpService.get('get-university?id=' + universityId);
  }
  saveUniversity(data: any): any {
    return this.httpService.post('save-university', data);
  }
  updateUniversity(data: any): any {
    return this.httpService.post('update-university', data);
  }
  deleteUniversity(universityId: number): any {
    return this.httpService.get('delete-university?id=' + universityId);
  }
  importUniversites(data: any): any {
    return this.httpService.post('import-universities', data);
  }

  //#endregion

  //#region Faculty
  getFaculties(isActive: boolean): any {
    return this.httpService.get('get-faculties?IsActive=' + isActive);
  }
  getFaculty(facultyId: number): any {
    return this.httpService.get('get-faculty?id=' + facultyId);
  }
  saveFaculty(data: any): any {
    return this.httpService.post('save-faculty', data);
  }
  updateFaculty(data: any): any {
    return this.httpService.post('update-faculty', data);
  }
  deleteFaculty(facultyId: number): any {
    return this.httpService.get('delete-faculty?id=' + facultyId);
  }
  importFaculties(data: any): any {
    return this.httpService.post('import-faculties', data);
  }
  //#endregion

  //#region Course Level
  getCourseLevels(isActive: boolean): any {
    return this.httpService.get('get-courselevels?isActive=' + isActive);
  }
  getCourseLevel(courseLevelId: number): any {
    return this.httpService.get('get-courselevel?id=' + courseLevelId);
  }
  saveCourseLevel(data: any): any {
    return this.httpService.post('save-courselevel', data);
  }
  updateCourseLevel(data: any): any {
    return this.httpService.post('update-courselevel', data);
  }
  deleteCourseLevel(courseLevelId: number): any {
    return this.httpService.get('delete-courselevel?id=' + courseLevelId);
  }
  importCourseLevels(data: any): any {
    return this.httpService.post('import-courselevels', data);
  }
  //#endregion

  //#region Course
  getCourses(data: any): any {
    return this.httpService.post('get-courses', data);
  }
  getCourse(courseId: number): any {
    return this.httpService.get('get-course?id=' + courseId);
  }
  saveCourse(data: any): any {
    return this.httpService.post('save-course', data);
  }
  updateCourse(data: any): any {
    return this.httpService.post('update-course', data);
  }
  deleteCourse(courseId: number): any {
    return this.httpService.get('delete-course?id=' + courseId);
  }
  importCourses(data: any): any {
    return this.httpService.post('import-courses', data);
  }
  //#endregion

  //#region Enquiry
  getEnquiries(data: any): any {
    return this.httpService.post('get-enquiries', data);
  }
  getEnquiry(enquiryId: number): any {
    return this.httpService.get('get-enquiry?id=' + enquiryId);
  }

  saveEnquiry(data: any): any {
    return this.httpService.post('save-enquiry', data);
  }

  updateEnquiry(data: any): any {
    return this.httpService.post('update-enquiry', data);
  }

  assignEnquiryToConsultant(data: any) {
    return this.httpService.post('assign-enquirytoconsultant', data);
  }

  //#endregion

  //#region Education Details
  saveEducationDetail(data: any): any {
    return this.httpService.post('save-educationdetail', data);
  }
  updateEducationDetail(data: any): any {
    return this.httpService.post('update-educationdetail', data);
  }
  getEducationDetails(userId: number): any {
    return this.httpService.get('get-educationdetails?userId=' + userId);
  }
  getEducationDetail(educationdetailId: number): any {
    return this.httpService.get('get-educationdetail?id=' + educationdetailId);
  }
  deleteEducationDetail(educationdetailId: number, enquiryId: number): any {
    return this.httpService.get('delete-educationdetail?id=' + educationdetailId + '&enquiryId=' + enquiryId);
  }
  //#endregion

  //#region Payments
  getPaymentDetails(enquiryId: number, isPaymentReceived: boolean, isPaymentAdd: boolean): any {
    return this.httpService.get('get-paymentdetails?enquiryId=' + enquiryId + '&isPaymentReceived=' + isPaymentReceived + '&isPaymentAdd=' + isPaymentAdd);
  }
  getPaymentRecord(paymentId: number): any {
    return this.httpService.get('get-paymentrecord?paymentId=' + paymentId);
  }
  savePayment(data: any): any {
    return this.httpService.post('save-payment', data);
  }
  updatePayment(data: any): any {
    return this.httpService.post('update-payment', data);
  }
  deletePayment(paymentId: number): any {
    return this.httpService.get('delete-paymentrecord?paymentId=' + paymentId);
  }

  getQuickPaymentSearch(data:any):any{
    return this.httpService.post('get-quick-payment-search', data);
  }

  saveQuickPayment(data:any):any{
    return this.httpService.post('save-quick-payment', data);
  }

  //#endregion

  //#region Exam Process
  saveExamProcess(data: any): any {
    return this.httpService.post('save-examprocess', data);
  }
  updateExamProcess(data: any): any {
    return this.httpService.post('update-examprocess', data);
  }
  getExamProcesses(enquiryId: number): any {
    return this.httpService.get('get-examprocesses?enquiryId=' + enquiryId);
  }
  getExamProcess(examProcessId: number): any {
    return this.httpService.get('get-examprocess?id=' + examProcessId);
  }
  deleteExamProcess(examProcessId: number): any {
    return this.httpService.get('delete-examprocess?id=' + examProcessId);
  }
  //#endregion

  //#region Application Process
  saveApplicationProcess(data: any): any {
    return this.httpService.post('save-applicationprocess', data);
  }
  updateApplicationProcess(data: any): any {
    return this.httpService.post('update-applicationprocess', data);
  }
  getApplicationProcesses(enquiryId: number): any {
    return this.httpService.get('get-applicationprocesses?enquiryId=' + enquiryId);
  }
  getApplicationProcess(applicationProcessId: number): any {
    return this.httpService.get('get-applicationprocess?id=' + applicationProcessId);
  }
  deleteApplicationProcess(applicationProcessId: number): any {
    return this.httpService.get('delete-applicationprocess?id=' + applicationProcessId);
  }
  //#endregion

  //#region User validation
  validateUser(data: any): any {
    return this.httpService.post('validate-user', data);
  }
  accessRequest(data: any): any {
    return this.httpService.post('appaccess-request', data);
  }
  getOrganisationLicenses(): any {
    return this.httpService.get('get-organisationlicenses');
  }
  updateLicense(data: any): any {
    return this.httpService.post('update-license', data);
  }
  resetUserPassword(userid: number): any {
    return this.httpService.get('reset-userpassword?userId=' + userid);
  }
  changePassword(data: any): any {
    return this.httpService.post('change-password', data);
  }

  verifyAppAccess(data: any): any {
    return this.httpService.post('verify-appaccess', data);
  }
  //#endregion

  //#region User
  saveUser(data: any): any {
    return this.httpService.post('save-user', data);
  }
  updateUser(data: any): any {
    return this.httpService.post('update-user', data);
  }
  getUsers(isActive: boolean): any {
    return this.httpService.get('get-users?isActive=' + isActive);
  }
  getUser(userId: number): any {
    return this.httpService.get('get-user?id=' + userId);
  }
  deleteUser(userId: number): any {
    return this.httpService.get('delete-user?id=' + userId);
  }
  //#endregion

  //#region Branch
  saveBranch(data: any): any {
    return this.httpService.post('save-branch', data);
  }
  updateBranch(data: any): any {
    return this.httpService.post('update-branch', data);
  }
  getBranches(isActive: boolean): any {
    return this.httpService.get('get-branches?isActive=' + isActive);
  }
  getBranch(branchId: number): any {
    return this.httpService.get('get-branch?id=' + branchId);
  }
  deleteBranch(userId: number): any {
    return this.httpService.get('delete-branch?id=' + userId);
  }
  //#endregion

  //#region Service Details
  saveService(data: any): any {
    return this.httpService.post('save-service', data);
  }
  updateService(data: any): any {
    return this.httpService.post('update-service', data);
  }
  getServices(isActive: boolean): any {
    return this.httpService.get('get-services?isActive=' + isActive);
  }
  getService(branchId: number): any {
    return this.httpService.get('get-service?id=' + branchId);
  }
  deleteService(userId: number): any {
    return this.httpService.get('delete-service?id=' + userId);
  }
  //#endregion

  saveUserType(data: any): any {
    return this.httpService.post('save-usertype', data);
  }
  updateUserType(data: any): any {
    return this.httpService.post('update-usertype', data);
  }
  getUserTypes(isActive: boolean): any {
    return this.httpService.get('get-userTypes?isActive=' + isActive);
  }
  getUserTypeRights(userTypeId: number): any {
    return this.httpService.get('get-usertyperghts?userTypeId=' + userTypeId);
  }
  deleteUserType(userTypeId: number): any {
    return this.httpService.get('delete-usertype?id=' + userTypeId);
  }
  updateUserTypeRight(data: any): any {
    return this.httpService.post('update-usertyperight', data);
  }

  //#region application process documents
  getApplicationProcessDocuments(applicationProcessId: Number): any {
    return this.httpService.get('get-applicationprocessdocuments?applicationProcessId=' + applicationProcessId);
  }
  saveApplicationProcessDocument(data: any): any {
    return this.httpService.post('save-applicationprocessdocument', data);
  }

  //#end application process documents


  //#region Service


  getHistory(data: any): any {
    return this.httpService.post('get-history', data);
  }

  generateGSTInvoice(enquiryId: number): any{
    return this.httpService.get('generate-gstinvoice?enquiryId='+enquiryId);
  }

  getGSTInvoices(enquiryId: number): any{
    return this.httpService.get('get-gstinvoices?enquiryId='+enquiryId);
  }
}
