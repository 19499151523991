import { Injectable } from '@angular/core';
import { QuickPaymentDialogComponent } from './quick-payment-dialog.component'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class QuickPaymentDialogService {


  constructor(public dialog: MatDialog) { }

  openDialog(foramData: any = null, height: string = null, width: string = null): void {
    const dialogRef = this.dialog.open(QuickPaymentDialogComponent, {
      data: foramData,
      height: height != null ? height : '515px',
      width: width != null ? width : '650px',
    });
  }
}
