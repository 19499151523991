import { Injectable } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';

import { UserRight } from '../../Modals/userright'
import { UserRightService } from '../data/api/userright.service'
import { Observable, ReplaySubject, observable } from "rxjs"

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  private userMenus: NbMenuItem[];
  private userRights: UserRight[];

  constructor(
    private userRightService: UserRightService
  ) { }

  setMenus(val: any) {
    this.userMenus = val;
  }

  setUserRights(val: any) {
    this.userRights = val;
  }

  getUserRights() {
    return this.userRights;
  }

  IsValidComponent(path: string, componentName: string): boolean {

    if (path === undefined) return false;
    let isEntryComponent: boolean = false;

    //for report
    if (componentName != undefined) {
      if (componentName === 'InvoiceComponent') {
        return true;
      }
      else if (componentName === 'GstInvoiceComponent') {
        return true;
      }
      else if (componentName ==='LoginHistoryComponent'){
        return true;
      }
      else if (componentName ==='ChangePasswordComponent'){
        return true;
      }
      else if (componentName ==='ReleaseNotesComponent'){
        return true;
      }
      else if (componentName ==='DashboardReportComponent'){
        return true;
      }
    }


    if (componentName != undefined) {
      if (componentName === 'UserEntryComponent') {
        path = '/pages/admin/user';
        isEntryComponent = true
      }
      else if (componentName === 'EnquiryEntryComponent') {
        path = '/pages/enquiry';
        isEntryComponent = true
      }
      else if (componentName === 'BranchEntryComponent') {
        path = '/pages/master/branch';
        isEntryComponent = true
      }
      else if (componentName === 'UniversityEntry') {
        path = '/pages/master/university';
        isEntryComponent = true
      }
      else if (componentName === 'FacultyEntryComponent') {
        path = '/pages/master/faculty';
        isEntryComponent = true
      }
      else if (componentName === 'CourseEntryComponent') {
        path = '/pages/master/course';
        isEntryComponent = true
      }
    }

    if (this.userRights === undefined) return true;

    let result = this.userRights.filter(x => x.link === path);

    if (isEntryComponent == true) {
      if (result != undefined && result != null) {
        if (result[0].add || result[0].edit) {
          return true;
        }
        else
          return false;
      }
    }
    else {
      if (result != undefined && result != null) {
        if (result[0].view) {
          return true;
        }
        else
          return false;
      }
    }
  }

  getUserId(): number {
    return Number(localStorage.getItem("userId"));
  }

  // create observable
  getRights(link: string): any {
    let rp = new ReplaySubject(1);
    if (this.userRights === undefined) {
      this.userRightService.getUserRight(link).then(res => rp.next(res.result));
      return rp;
    }
    else {
      let result = this.userRights.filter(x => x.link === link);
      let userRight = result[0];
      rp.next(userRight);
      return rp;
    }
  }

  getRights2(link: string, Add: any, Edit: any, Delete: any): any {

    Add = false;
    Edit = true;
    Delete = true;


    // if (this.userRights === undefined) {
    //   this.userRightService.getUserRight(link).then(res =>{
    //     let data = <UserRight>res.result
    //     Add=>{Add.next(data.add!)}
    //     Edit=>{Edit.next(data.add!)}
    //     Delete=>{Delete.next(data.add!)}


    //   });
    // }
    // else {
    //   let result = this.userRights.filter(x => x.link === link);
    //   let userRight = result[0];
    //   let data = userRight;
    //   Add=>{Add.next(data.add!)}
    //   Edit=>{Edit.next(data.add!)}
    //   Delete=>{Delete.next(data.add!)}
    // }
  }

}
