import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';

let counter = 0;
@Injectable()
export class UsersService {

  private users = {
    nirav : {name : "Nirav prajapati", picture : ''},
    milan : {name : "Milan Panchal", picture : 'assets/images/milan.png'},
    anand : {name : "Ananad Shah", picture : 'assets/images/anand.png'},
  }

  private userArray: any[];
  
  constructor() { }

  getUsers(): Observable<any>
  {
    return observableOf(this.users);
  }

  getUsersArray(): Observable<any[]>{
    return observableOf(this.userArray);
  }

  getUser(): Observable<any> {
    //counter = (counter + 1) % this.userArray.length;
    return observableOf(this.users.nirav);
  }
}
