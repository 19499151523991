import { Injectable } from '@angular/core';
import { HttpService } from '../http.service'

@Injectable({
  providedIn: 'root'
})
export class EnquiryService {

  constructor(private httpService: HttpService) { }

  saveEnquiryNote(data: any): any {
    return this.httpService.post('save-enquirynote', data);
  }

  getEnquiryNotes(enquiryId: number): any {
    return this.httpService.get('get-enquirynotes?enquiryId=' + enquiryId);
  }

  filterEnquiryRecords(search: string): any {
    return this.httpService.get('filter-enquiryRecords?search=' + search);
  }
}
