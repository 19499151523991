import { Injectable } from '@angular/core';
import { HttpService } from '../http.service'


@Injectable({
  providedIn: 'root'
})
export class FolloUpService {

  constructor(private httpService: HttpService) { }

  saveFollowUp(data: any): any {
    return this.httpService.post('save-followup', data);
  }

  updateFollowUpStatus(followupId: number, status: string): any {
    return this.httpService.get('update-followupstatus?id=' + followupId + '&status=' + status);
  }

}
