import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader'

@Injectable()
export class ProgressbarService {

  constructor(
    private ngxService: NgxUiLoaderService,
    ) { }

    //Users cannot interact with the page when the loader is showed.
   start(){
    this.ngxService.start();

     // Stop the foreground loading after 10s
    setTimeout(() => {
      this.ngxService.stop(); // stop foreground loading with 'default' id
    }, 10000);
   }

   stop(){
    this.ngxService.stop();
   }
  
   //Users can interact with the page when the loader is showed.
   startBackground(){
     this.ngxService.startBackground();

     setTimeout(() => {
       this.ngxService.stopBackground(); // stop foreground loading with 'default' id
    }, 10000);
   }

   stopBackground(){
     this.ngxService.stopBackground();
   }   
}



